import React from "react";
import { Root } from "./style";

import RawHTML from "components/Common/RawHTML";
import JoinTheCommunities from "components/Common/JoinTheCommunities";
import ShareLinks from "components/Common/ShareLinks";

import vars from "variables";

const ItemPage = ({ location, pageContext: { item } }) => {
  return (
    <Root className="body-width">
      <div
        className="image"
        style={{ backgroundImage: `url(${vars.assetsPrefix}/${item.image})` }}
      />
      <h1>{item.title}</h1>
      <ShareLinks location={location} item={item} />
      <RawHTML className="content" html={item.content} />
      <JoinTheCommunities />
    </Root>
  );
};

export default ItemPage;
